import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IInputWrapper {
    isLast?: boolean;
    isFirst?: boolean;
    isInFocus: boolean;
    isHovered: boolean;
    children: ReactNode;
    isTextBold?: boolean;
    isSubFilter?: boolean;
    isCursorPointed?: boolean;
    inputWidth: number | string;
    onMouseHover?: (flag: boolean) => void;
}

export const InputWrapper: FC<IInputWrapper> = ({
    isLast,
    isFirst,
    children,
    isInFocus,
    inputWidth,
    isTextBold,
    isSubFilter,
    onMouseHover,
    isCursorPointed,
}) => {
    let transformProp = 'translateY(-50%)';
    if (!isFirst && !isLast) {
        transformProp += ' scaleX(1.06)';
    }

    let width = '100%';
    if (isLast || isFirst) {
        width = 'calc(100% + 10px)';
    }

    return <Box
        onMouseEnter={() => {
            onMouseHover(true);
        }}
        onMouseLeave={() => {
            onMouseHover(false);
        }}
        sx={(theme) => ({
            width: inputWidth,
            '&::before': {
                width,
                top: '50%',
                height: 63,
                content: '""',
                position: 'absolute',
                borderRadius: '99px',
                paddingLeft: 20,
                transform: transformProp,
                marginLeft: (isLast && !isSubFilter) && '-4%',
                marginRight: (isFirst && !isSubFilter) && '4%',
                display: !isInFocus && 'none',
                backgroundColor: isInFocus
                    ? theme.palette.backgroundPrimary.main
                    : theme.palette.backgroundTertiary.main,
                boxShadow: isInFocus &&
                    '0px 4px 16px 0px rgba(0, 0, 0, 0.08);',
            },
            '&:hover::before': {
                display: !isSubFilter && 'inline-block',
                backgroundColor: (!isInFocus && !isSubFilter) &&
                    theme.palette.dividerTransparent,
            },
            position: 'relative',
            paddingRight: isLast && 1.25,
            '& .MuiAutocomplete-root': {
                '& .MuiOutlinedInput-root': {
                    paddingBottom: '6px!important',
                    paddingTop: '9px!important',
                    '& input': {
                        paddingBottom: '6px!important',
                        paddingTop: '9px!important',
                    },
                },
                '&:has(label) .MuiOutlinedInput-root': {
                    paddingBottom: '6px !important',
                    '& .MuiInputBase-input': {
                        paddingLeft: '6px',
                        paddingBottom: '6px !important',
                    },
                },
            },
            '& .MuiOutlinedInput-root': {
                zIndex: 4,
                height: '40px!important',
                paddingY: '0px!important',
                cursor: isCursorPointed && 'pointer',
                background: 'transparent',
                '& input': {
                    fontSize: '14px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: isTextBold ? 600: 400,
                    paddingBottom: '0px!important',
                    cursor: isCursorPointed ? 'pointer' : 'default',
                    caretColor: isCursorPointed ? 'transparent' : 'inherit',
                    paddingRight: (isLast && isInFocus) ? '0px!important' : 0,
                    '@supports (-webkit-overflow-scrolling: touch)': {
                        fontSize: '16px',
                    },
                },
                '& fieldset': {
                    paddingLeft: 0,
                    paddingRight: 0,
                    border: 'none!important',
                },
                '&.Mui-focused': {
                    zIndex: 4,
                    '& fieldset': {
                        border: 'none!important',
                        transform: 'scale(0)',
                    },
                    '& input': {
                        paddingBottom: '0px!important',
                        paddingRight: isLast && '0px!important',
                    },
                },
                '& .MuiAutocomplete-popupIndicator': {
                    display: 'none'
                }
            },
            '& .MuiInputLabel-root': {
                top: '-5px',
                zIndex: 5,
                fontWeight: 600,
                color: theme.palette.labelPrimary.main,
                transform: 'translate(15px, 5px) scale(1)',
                '&.Mui-focused': {
                    color: theme.palette.labelPrimary.main
                },
            },
        })}
    >
        {children}
    </Box>;
};