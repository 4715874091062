import { FC } from 'react';
import { Grid2 } from '@mui/material';
import { ILocation } from 'modules/api/clients/location/interfaces/location/ILocation';
import {
    baseLocationIconRecord
} from 'components/advertisementsFilter/constants/record/location/baseLocationIconRecord';
import {
    LocationRadioCard
} from 'components/advertisementsFilter/components/common/common/radioCard/location/LocationRadioCard';

interface IBaseLocations {
    options: ILocation[];
    selectedLocationId: string;
    onChangeLocation: (location: ILocation) => void;
}
export const BaseLocations: FC<IBaseLocations> = ({
    options,
    onChangeLocation,
    selectedLocationId,
}) => {
    return <Grid2
        container
        spacing={1}
        maxWidth={{ xs: '100%', md: 400 }}
    >
        {options.map((locationOption) =>
            <Grid2 size={{xs: 4}} key={locationOption.id}>
                <LocationRadioCard
                    isMain
                    label={locationOption.name}
                    onClick={() => onChangeLocation(locationOption)}
                    icon={baseLocationIconRecord[locationOption.sefName]}
                    isChecked={selectedLocationId === locationOption.id}
                />
            </Grid2>
        )}
    </Grid2>;
};