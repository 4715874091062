import { AdvertisementsListMetaDto } from 'modules/api/generated';
import { IAdvertisementsListMeta } from 'modules/api/clients/advertisement/listing/interfaces/advertisementsList/partials/meta/IAdvertisementsListMeta';

export const mapMetaDtoToIAdvertisementsListMeta = ({
    title,
    description,
}: AdvertisementsListMetaDto): IAdvertisementsListMeta => {
    return {
        title,
        description,
    };
};
